@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?ddjaar');
  src:  url('fonts/icomoon.eot?ddjaar#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?ddjaar') format('truetype'),
    url('fonts/icomoon.woff?ddjaar') format('woff'),
    url('fonts/icomoon.svg?ddjaar#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-push-down {
  position: relative;
  top: .1rem;
}

.icon-home:before {
  content: "\e98e";
}
.icon-home2:before {
  content: "\e98f";
}
.icon-home3:before {
  content: "\e990";
}
.icon-office:before {
  content: "\e991";
}
.icon-newspaper:before {
  content: "\e992";
}
.icon-pencil:before {
  content: "\e993";
}
.icon-pencil2:before {
  content: "\e994";
}
.icon-quill:before {
  content: "\e995";
}
.icon-pen:before {
  content: "\e996";
}
.icon-blog:before {
  content: "\e997";
}
.icon-eyedropper:before {
  content: "\e998";
}
.icon-droplet:before {
  content: "\e999";
}
.icon-paint-format:before {
  content: "\e99a";
}
.icon-image:before {
  content: "\e99b";
}
.icon-images:before {
  content: "\e99c";
}
.icon-camera:before {
  content: "\e99d";
}
.icon-headphones:before {
  content: "\e99e";
}
.icon-music:before {
  content: "\e99f";
}
.icon-play:before {
  content: "\e9a0";
}
.icon-film:before {
  content: "\e9a1";
}
.icon-video-camera:before {
  content: "\e9a2";
}
.icon-dice:before {
  content: "\e9a3";
}
.icon-pacman:before {
  content: "\e9a4";
}
.icon-spades:before {
  content: "\e9a5";
}
.icon-clubs:before {
  content: "\e9a6";
}
.icon-diamonds:before {
  content: "\e9a7";
}
.icon-bullhorn:before {
  content: "\e9a8";
}
.icon-connection:before {
  content: "\e9a9";
}
.icon-podcast:before {
  content: "\e9aa";
}
.icon-feed:before {
  content: "\e9ab";
}
.icon-mic:before {
  content: "\e9ac";
}
.icon-book:before {
  content: "\e9ad";
}
.icon-books:before {
  content: "\e9ae";
}
.icon-library:before {
  content: "\e9af";
}
.icon-file-text:before {
  content: "\e9b0";
}
.icon-profile:before {
  content: "\e9b1";
}
.icon-file-empty:before {
  content: "\e9b2";
}
.icon-files-empty:before {
  content: "\e9b3";
}
.icon-file-text2:before {
  content: "\e9b4";
}
.icon-file-picture:before {
  content: "\e9b5";
}
.icon-file-music:before {
  content: "\e9b6";
}
.icon-file-play:before {
  content: "\e9b7";
}
.icon-file-video:before {
  content: "\e9b8";
}
.icon-file-zip:before {
  content: "\e9b9";
}
.icon-copy:before {
  content: "\e9ba";
}
.icon-paste:before {
  content: "\e9bb";
}
.icon-stack:before {
  content: "\e9bc";
}
.icon-folder:before {
  content: "\e9bd";
}
.icon-folder-open:before {
  content: "\e9be";
}
.icon-folder-plus:before {
  content: "\e9bf";
}
.icon-folder-minus:before {
  content: "\e9c0";
}
.icon-folder-download:before {
  content: "\e9c1";
}
.icon-folder-upload:before {
  content: "\e9c2";
}
.icon-price-tag:before {
  content: "\e9c3";
}
.icon-price-tags:before {
  content: "\e9c4";
}
.icon-barcode:before {
  content: "\e9c5";
}
.icon-qrcode:before {
  content: "\e9c6";
}
.icon-ticket:before {
  content: "\e9c7";
}
.icon-cart:before {
  content: "\e9c8";
}
.icon-coin-dollar:before {
  content: "\e9c9";
}
.icon-coin-euro:before {
  content: "\e9ca";
}
.icon-coin-pound:before {
  content: "\e9cb";
}
.icon-coin-yen:before {
  content: "\e9cc";
}
.icon-credit-card:before {
  content: "\e9cd";
}
.icon-calculator:before {
  content: "\e9ce";
}
.icon-lifebuoy:before {
  content: "\e9cf";
}
.icon-phone:before {
  content: "\e9d0";
}
.icon-phone-hang-up:before {
  content: "\e9d1";
}
.icon-address-book:before {
  content: "\e9d2";
}
.icon-envelop:before {
  content: "\e9d3";
}
.icon-pushpin:before {
  content: "\e9d4";
}
.icon-location:before {
  content: "\e9d5";
}
.icon-location2:before {
  content: "\e9d6";
}
.icon-compass:before {
  content: "\e9d7";
}
.icon-compass2:before {
  content: "\e9d8";
}
.icon-map:before {
  content: "\e9d9";
}
.icon-map2:before {
  content: "\e9da";
}
.icon-history:before {
  content: "\e9db";
}
.icon-clock:before {
  content: "\e9dc";
}
.icon-clock2:before {
  content: "\e9dd";
}
.icon-alarm:before {
  content: "\e9de";
}
.icon-bell:before {
  content: "\e9df";
}
.icon-stopwatch:before {
  content: "\e9e0";
}
.icon-calendar:before {
  content: "\e9e1";
}
.icon-printer:before {
  content: "\e9e2";
}
.icon-keyboard:before {
  content: "\e9e3";
}
.icon-display:before {
  content: "\e9e4";
}
.icon-laptop:before {
  content: "\e9e5";
}
.icon-mobile:before {
  content: "\e9e6";
}
.icon-mobile2:before {
  content: "\e9e7";
}
.icon-tablet:before {
  content: "\e9e8";
}
.icon-tv:before {
  content: "\e9e9";
}
.icon-drawer:before {
  content: "\e9ea";
}
.icon-drawer2:before {
  content: "\e9eb";
}
.icon-box-add:before {
  content: "\e9ec";
}
.icon-box-remove:before {
  content: "\e9ed";
}
.icon-download:before {
  content: "\e9ee";
}
.icon-upload:before {
  content: "\e9ef";
}
.icon-floppy-disk:before {
  content: "\e9f0";
}
.icon-drive:before {
  content: "\e9f1";
}
.icon-database:before {
  content: "\e9f2";
}
.icon-undo:before {
  content: "\e9f3";
}
.icon-redo:before {
  content: "\e9f4";
}
.icon-undo2:before {
  content: "\e9f5";
}
.icon-redo2:before {
  content: "\e9f6";
}
.icon-forward:before {
  content: "\e9f7";
}
.icon-reply:before {
  content: "\e9f8";
}
.icon-bubble:before {
  content: "\e9f9";
}
.icon-bubbles:before {
  content: "\e9fa";
}
.icon-bubbles2:before {
  content: "\e9fb";
}
.icon-bubble2:before {
  content: "\e9fc";
}
.icon-bubbles3:before {
  content: "\e9fd";
}
.icon-bubbles4:before {
  content: "\e9fe";
}
.icon-user:before {
  content: "\e9ff";
}
.icon-users:before {
  content: "\ea00";
}
.icon-user-plus:before {
  content: "\ea01";
}
.icon-user-minus:before {
  content: "\ea02";
}
.icon-user-check:before {
  content: "\ea03";
}
.icon-user-tie:before {
  content: "\ea04";
}
.icon-quotes-left:before {
  content: "\ea05";
}
.icon-quotes-right:before {
  content: "\ea06";
}
.icon-hour-glass:before {
  content: "\ea07";
}
.icon-spinner:before {
  content: "\ea08";
}
.icon-spinner2:before {
  content: "\ea09";
}
.icon-spinner3:before {
  content: "\ea0a";
}
.icon-spinner4:before {
  content: "\ea0b";
}
.icon-spinner5:before {
  content: "\ea0c";
}
.icon-spinner6:before {
  content: "\ea0d";
}
.icon-spinner7:before {
  content: "\ea0e";
}
.icon-spinner8:before {
  content: "\ea0f";
}
.icon-spinner9:before {
  content: "\ea10";
}
.icon-spinner10:before {
  content: "\ea11";
}
.icon-spinner11:before {
  content: "\ea12";
}
.icon-binoculars:before {
  content: "\ea13";
}
.icon-search:before {
  content: "\ea14";
}
.icon-zoom-in:before {
  content: "\ea15";
}
.icon-zoom-out:before {
  content: "\ea16";
}
.icon-enlarge:before {
  content: "\ea17";
}
.icon-shrink:before {
  content: "\ea18";
}
.icon-enlarge2:before {
  content: "\ea19";
}
.icon-shrink2:before {
  content: "\ea1a";
}
.icon-key:before {
  content: "\ea1b";
}
.icon-key2:before {
  content: "\ea1c";
}
.icon-lock:before {
  content: "\ea1d";
}
.icon-unlocked:before {
  content: "\ea1e";
}
.icon-wrench:before {
  content: "\ea1f";
}
.icon-equalizer:before {
  content: "\ea20";
}
.icon-equalizer2:before {
  content: "\ea21";
}
.icon-cog:before {
  content: "\ea22";
}
.icon-cogs:before {
  content: "\ea23";
}
.icon-hammer:before {
  content: "\ea24";
}
.icon-magic-wand:before {
  content: "\ea25";
}
.icon-aid-kit:before {
  content: "\ea26";
}
.icon-bug:before {
  content: "\ea27";
}
.icon-pie-chart:before {
  content: "\ea28";
}
.icon-stats-dots:before {
  content: "\ea29";
}
.icon-stats-bars:before {
  content: "\ea2a";
}
.icon-stats-bars2:before {
  content: "\ea2b";
}
.icon-trophy:before {
  content: "\ea2c";
}
.icon-gift:before {
  content: "\ea2d";
}
.icon-glass:before {
  content: "\ea2e";
}
.icon-glass2:before {
  content: "\ea2f";
}
.icon-mug:before {
  content: "\ea30";
}
.icon-spoon-knife:before {
  content: "\ea31";
}
.icon-leaf:before {
  content: "\ea32";
}
.icon-rocket:before {
  content: "\ea33";
}
.icon-meter:before {
  content: "\ea34";
}
.icon-meter2:before {
  content: "\ea35";
}
.icon-hammer2:before {
  content: "\ea36";
}
.icon-fire:before {
  content: "\ea37";
}
.icon-lab:before {
  content: "\ea38";
}
.icon-magnet:before {
  content: "\ea39";
}
.icon-bin:before {
  content: "\ea3a";
}
.icon-bin2:before {
  content: "\ea3b";
}
.icon-briefcase:before {
  content: "\ea3c";
}
.icon-airplane:before {
  content: "\ea3d";
}
.icon-truck:before {
  content: "\ea3e";
}
.icon-road:before {
  content: "\ea3f";
}
.icon-accessibility:before {
  content: "\ea40";
}
.icon-target:before {
  content: "\ea41";
}
.icon-shield:before {
  content: "\ea42";
}
.icon-power:before {
  content: "\ea43";
}
.icon-switch:before {
  content: "\ea44";
}
.icon-power-cord:before {
  content: "\ea45";
}
.icon-clipboard:before {
  content: "\ea46";
}
.icon-list-numbered:before {
  content: "\ea47";
}
.icon-list:before {
  content: "\ea48";
}
.icon-list2:before {
  content: "\ea49";
}
.icon-tree:before {
  content: "\ea4a";
}
.icon-menu:before {
  content: "\ea4b";
}
.icon-menu2:before {
  content: "\ea4c";
}
.icon-menu3:before {
  content: "\ea4d";
}
.icon-menu4:before {
  content: "\ea4e";
}
.icon-cloud:before {
  content: "\ea4f";
}
.icon-cloud-download:before {
  content: "\ea50";
}
.icon-cloud-upload:before {
  content: "\ea51";
}
.icon-cloud-check:before {
  content: "\ea52";
}
.icon-download2:before {
  content: "\ea53";
}
.icon-upload2:before {
  content: "\ea54";
}
.icon-download3:before {
  content: "\ea55";
}
.icon-upload3:before {
  content: "\ea56";
}
.icon-sphere:before {
  content: "\ea57";
}
.icon-earth:before {
  content: "\ea58";
}
.icon-link:before {
  content: "\ea59";
}
.icon-flag:before {
  content: "\ea5a";
}
.icon-attachment:before {
  content: "\ea5b";
}
.icon-eye:before {
  content: "\ea5c";
}
.icon-eye-plus:before {
  content: "\ea5d";
}
.icon-eye-minus:before {
  content: "\ea5e";
}
.icon-eye-blocked:before {
  content: "\ea5f";
}
.icon-bookmark:before {
  content: "\ea60";
}
.icon-bookmarks:before {
  content: "\ea61";
}
.icon-sun:before {
  content: "\ea62";
}
.icon-contrast:before {
  content: "\ea63";
}
.icon-brightness-contrast:before {
  content: "\ea64";
}
.icon-star-empty:before {
  content: "\ea65";
}
.icon-star-half:before {
  content: "\ea66";
}
.icon-star-full:before {
  content: "\ea67";
}
.icon-heart:before {
  content: "\ea68";
}
.icon-heart-broken:before {
  content: "\ea69";
}
.icon-man:before {
  content: "\ea6a";
}
.icon-woman:before {
  content: "\ea6b";
}
.icon-man-woman:before {
  content: "\ea6c";
}
.icon-happy:before {
  content: "\ea6d";
}
.icon-happy2:before {
  content: "\ea6e";
}
.icon-smile:before {
  content: "\ea6f";
}
.icon-smile2:before {
  content: "\ea70";
}
.icon-tongue:before {
  content: "\ea71";
}
.icon-tongue2:before {
  content: "\ea72";
}
.icon-sad:before {
  content: "\ea73";
}
.icon-sad2:before {
  content: "\ea74";
}
.icon-wink:before {
  content: "\ea75";
}
.icon-wink2:before {
  content: "\ea76";
}
.icon-grin:before {
  content: "\ea77";
}
.icon-grin2:before {
  content: "\ea78";
}
.icon-cool:before {
  content: "\ea79";
}
.icon-cool2:before {
  content: "\ea7a";
}
.icon-angry:before {
  content: "\ea7b";
}
.icon-angry2:before {
  content: "\ea7c";
}
.icon-evil:before {
  content: "\ea7d";
}
.icon-evil2:before {
  content: "\ea7e";
}
.icon-shocked:before {
  content: "\ea7f";
}
.icon-shocked2:before {
  content: "\ea80";
}
.icon-baffled:before {
  content: "\ea81";
}
.icon-baffled2:before {
  content: "\ea82";
}
.icon-confused:before {
  content: "\ea83";
}
.icon-confused2:before {
  content: "\ea84";
}
.icon-neutral:before {
  content: "\ea85";
}
.icon-neutral2:before {
  content: "\ea86";
}
.icon-hipster:before {
  content: "\ea87";
}
.icon-hipster2:before {
  content: "\ea88";
}
.icon-wondering:before {
  content: "\ea89";
}
.icon-wondering2:before {
  content: "\ea8a";
}
.icon-sleepy:before {
  content: "\ea8b";
}
.icon-sleepy2:before {
  content: "\ea8c";
}
.icon-frustrated:before {
  content: "\ea8d";
}
.icon-frustrated2:before {
  content: "\ea8e";
}
.icon-crying:before {
  content: "\ea8f";
}
.icon-crying2:before {
  content: "\ea90";
}
.icon-point-up:before {
  content: "\ea91";
}
.icon-point-right:before {
  content: "\ea92";
}
.icon-point-down:before {
  content: "\ea93";
}
.icon-point-left:before {
  content: "\ea94";
}
.icon-warning:before {
  content: "\ea95";
}
.icon-notification:before {
  content: "\ea96";
}
.icon-question:before {
  content: "\ea97";
}
.icon-plus:before {
  content: "\ea98";
}
.icon-minus:before {
  content: "\ea99";
}
.icon-info:before {
  content: "\ea9a";
}
.icon-cancel-circle:before {
  content: "\ea9b";
}
.icon-blocked:before {
  content: "\ea9c";
}
.icon-cross:before {
  content: "\ea9d";
}
.icon-checkmark:before {
  content: "\ea9e";
}
.icon-checkmark2:before {
  content: "\ea9f";
}
.icon-spell-check:before {
  content: "\eaa0";
}
.icon-enter:before {
  content: "\eaa1";
}
.icon-exit:before {
  content: "\eaa2";
}
.icon-play2:before {
  content: "\eaa3";
}
.icon-pause:before {
  content: "\eaa4";
}
.icon-stop:before {
  content: "\eaa5";
}
.icon-previous:before {
  content: "\eaa6";
}
.icon-next:before {
  content: "\eaa7";
}
.icon-backward:before {
  content: "\eaa8";
}
.icon-forward2:before {
  content: "\eaa9";
}
.icon-play3:before {
  content: "\eaaa";
}
.icon-pause2:before {
  content: "\eaab";
}
.icon-stop2:before {
  content: "\eaac";
}
.icon-backward2:before {
  content: "\eaad";
}
.icon-forward3:before {
  content: "\eaae";
}
.icon-first:before {
  content: "\eaaf";
}
.icon-last:before {
  content: "\eab0";
}
.icon-previous2:before {
  content: "\eab1";
}
.icon-next2:before {
  content: "\eab2";
}
.icon-eject:before {
  content: "\eab3";
}
.icon-volume-high:before {
  content: "\eab4";
}
.icon-volume-medium:before {
  content: "\eab5";
}
.icon-volume-low:before {
  content: "\eab6";
}
.icon-volume-mute:before {
  content: "\eab7";
}
.icon-volume-mute2:before {
  content: "\eab8";
}
.icon-volume-increase:before {
  content: "\eab9";
}
.icon-volume-decrease:before {
  content: "\eaba";
}
.icon-loop:before {
  content: "\eabb";
}
.icon-loop2:before {
  content: "\eabc";
}
.icon-infinite:before {
  content: "\eabd";
}
.icon-shuffle:before {
  content: "\eabe";
}
.icon-arrow-up-left:before {
  content: "\eabf";
}
.icon-arrow-up:before {
  content: "\eac0";
}
.icon-arrow-up-right:before {
  content: "\eac1";
}
.icon-arrow-right:before {
  content: "\eac2";
}
.icon-arrow-down-right:before {
  content: "\eac3";
}
.icon-arrow-down:before {
  content: "\eac4";
}
.icon-arrow-down-left:before {
  content: "\eac5";
}
.icon-arrow-left:before {
  content: "\eac6";
}
.icon-arrow-up-left2:before {
  content: "\eac7";
}
.icon-arrow-up2:before {
  content: "\eac8";
}
.icon-arrow-up-right2:before {
  content: "\eac9";
}
.icon-arrow-right2:before {
  content: "\eaca";
}
.icon-arrow-down-right2:before {
  content: "\eacb";
}
.icon-arrow-down2:before {
  content: "\eacc";
}
.icon-arrow-down-left2:before {
  content: "\eacd";
}
.icon-arrow-left2:before {
  content: "\eace";
}
.icon-circle-up:before {
  content: "\eacf";
}
.icon-circle-right:before {
  content: "\ead0";
}
.icon-circle-down:before {
  content: "\ead1";
}
.icon-circle-left:before {
  content: "\ead2";
}
.icon-tab:before {
  content: "\ead3";
}
.icon-move-up:before {
  content: "\ead4";
}
.icon-move-down:before {
  content: "\ead5";
}
.icon-sort-alpha-asc:before {
  content: "\ead6";
}
.icon-sort-alpha-desc:before {
  content: "\ead7";
}
.icon-sort-numeric-asc:before {
  content: "\ead8";
}
.icon-sort-numberic-desc:before {
  content: "\ead9";
}
.icon-sort-amount-asc:before {
  content: "\eada";
}
.icon-sort-amount-desc:before {
  content: "\eadb";
}
.icon-command:before {
  content: "\eadc";
}
.icon-shift:before {
  content: "\eadd";
}
.icon-ctrl:before {
  content: "\eade";
}
.icon-opt:before {
  content: "\eadf";
}
.icon-checkbox-checked:before {
  content: "\eae0";
}
.icon-checkbox-unchecked:before {
  content: "\eae1";
}
.icon-radio-checked:before {
  content: "\eae2";
}
.icon-radio-checked2:before {
  content: "\eae3";
}
.icon-radio-unchecked:before {
  content: "\eae4";
}
.icon-crop:before {
  content: "\eae5";
}
.icon-make-group:before {
  content: "\eae6";
}
.icon-ungroup:before {
  content: "\eae7";
}
.icon-scissors:before {
  content: "\eae8";
}
.icon-filter:before {
  content: "\eae9";
}
.icon-font:before {
  content: "\eaea";
}
.icon-ligature:before {
  content: "\eaeb";
}
.icon-ligature2:before {
  content: "\eaec";
}
.icon-text-height:before {
  content: "\eaed";
}
.icon-text-width:before {
  content: "\eaee";
}
.icon-font-size:before {
  content: "\eaef";
}
.icon-bold:before {
  content: "\eaf0";
}
.icon-underline:before {
  content: "\eaf1";
}
.icon-italic:before {
  content: "\eaf2";
}
.icon-strikethrough:before {
  content: "\eaf3";
}
.icon-omega:before {
  content: "\eaf4";
}
.icon-sigma:before {
  content: "\eaf5";
}
.icon-page-break:before {
  content: "\eaf6";
}
.icon-superscript:before {
  content: "\eaf7";
}
.icon-subscript:before {
  content: "\eaf8";
}
.icon-superscript2:before {
  content: "\eaf9";
}
.icon-subscript2:before {
  content: "\eafa";
}
.icon-text-color:before {
  content: "\eafb";
}
.icon-pagebreak:before {
  content: "\eafc";
}
.icon-clear-formatting:before {
  content: "\eafd";
}
.icon-table:before {
  content: "\eafe";
}
.icon-table2:before {
  content: "\eaff";
}
.icon-insert-template:before {
  content: "\eb00";
}
.icon-pilcrow:before {
  content: "\eb01";
}
.icon-ltr:before {
  content: "\eb02";
}
.icon-rtl:before {
  content: "\eb03";
}
.icon-section:before {
  content: "\eb04";
}
.icon-paragraph-left:before {
  content: "\eb05";
}
.icon-paragraph-center:before {
  content: "\eb06";
}
.icon-paragraph-right:before {
  content: "\eb07";
}
.icon-paragraph-justify:before {
  content: "\eb08";
}
.icon-indent-increase:before {
  content: "\eb09";
}
.icon-indent-decrease:before {
  content: "\eb0a";
}
.icon-share:before {
  content: "\eb0b";
}
.icon-new-tab:before {
  content: "\eb0c";
}
.icon-embed:before {
  content: "\eb0d";
}
.icon-embed2:before {
  content: "\eb0e";
}
.icon-terminal:before {
  content: "\eb0f";
}
.icon-share2:before {
  content: "\eb10";
}
.icon-mail:before {
  content: "\eb11";
}
.icon-mail2:before {
  content: "\eb12";
}
.icon-mail3:before {
  content: "\eb13";
}
.icon-mail4:before {
  content: "\eb14";
}
.icon-amazon:before {
  content: "\eb15";
}
.icon-google:before {
  content: "\eb16";
}
.icon-google2:before {
  content: "\eb17";
}
.icon-google3:before {
  content: "\eb18";
}
.icon-google-plus:before {
  content: "\eb19";
}
.icon-google-plus2:before {
  content: "\eb1a";
}
.icon-google-plus3:before {
  content: "\eb1b";
}
.icon-hangouts:before {
  content: "\eb1c";
}
.icon-google-drive:before {
  content: "\eb1d";
}
.icon-facebook:before {
  content: "\eb1e";
}
.icon-facebook2:before {
  content: "\eb1f";
}
.icon-instagram:before {
  content: "\eb20";
}
.icon-whatsapp:before {
  content: "\eb21";
}
.icon-spotify:before {
  content: "\eb22";
}
.icon-telegram:before {
  content: "\eb23";
}
.icon-twitter:before {
  content: "\eb24";
}
.icon-vine:before {
  content: "\eb25";
}
.icon-vk:before {
  content: "\eb26";
}
.icon-renren:before {
  content: "\eb27";
}
.icon-sina-weibo:before {
  content: "\eb28";
}
.icon-rss:before {
  content: "\eb29";
}
.icon-rss2:before {
  content: "\eb2a";
}
.icon-youtube:before {
  content: "\eb2b";
}
.icon-youtube2:before {
  content: "\eb2c";
}
.icon-twitch:before {
  content: "\eb2d";
}
.icon-vimeo:before {
  content: "\eb2e";
}
.icon-vimeo2:before {
  content: "\eb2f";
}
.icon-lanyrd:before {
  content: "\eb30";
}
.icon-flickr:before {
  content: "\eb31";
}
.icon-flickr2:before {
  content: "\eb32";
}
.icon-flickr3:before {
  content: "\eb33";
}
.icon-flickr4:before {
  content: "\eb34";
}
.icon-dribbble:before {
  content: "\eb35";
}
.icon-behance:before {
  content: "\eb36";
}
.icon-behance2:before {
  content: "\eb37";
}
.icon-deviantart:before {
  content: "\eb38";
}
.icon-500px:before {
  content: "\eb39";
}
.icon-steam:before {
  content: "\eb3a";
}
.icon-steam2:before {
  content: "\eb3b";
}
.icon-dropbox:before {
  content: "\eb3c";
}
.icon-onedrive:before {
  content: "\eb3d";
}
.icon-github:before {
  content: "\eb3e";
}
.icon-npm:before {
  content: "\eb3f";
}
.icon-basecamp:before {
  content: "\eb40";
}
.icon-trello:before {
  content: "\eb41";
}
.icon-wordpress:before {
  content: "\eb42";
}
.icon-joomla:before {
  content: "\eb43";
}
.icon-ello:before {
  content: "\eb44";
}
.icon-blogger:before {
  content: "\eb45";
}
.icon-blogger2:before {
  content: "\eb46";
}
.icon-tumblr:before {
  content: "\eb47";
}
.icon-tumblr2:before {
  content: "\eb48";
}
.icon-yahoo:before {
  content: "\eb49";
}
.icon-yahoo2:before {
  content: "\eb4a";
}
.icon-tux:before {
  content: "\eb4b";
}
.icon-appleinc:before {
  content: "\eb4c";
}
.icon-finder:before {
  content: "\eb4d";
}
.icon-android:before {
  content: "\eb4e";
}
.icon-windows:before {
  content: "\eb4f";
}
.icon-windows8:before {
  content: "\eb50";
}
.icon-soundcloud:before {
  content: "\eb51";
}
.icon-soundcloud2:before {
  content: "\eb52";
}
.icon-skype:before {
  content: "\eb53";
}
.icon-reddit:before {
  content: "\eb54";
}
.icon-hackernews:before {
  content: "\eb55";
}
.icon-wikipedia:before {
  content: "\eb56";
}
.icon-linkedin:before {
  content: "\eb57";
}
.icon-linkedin2:before {
  content: "\eb58";
}
.icon-lastfm:before {
  content: "\eb59";
}
.icon-lastfm2:before {
  content: "\eb5a";
}
.icon-delicious:before {
  content: "\eb5b";
}
.icon-stumbleupon:before {
  content: "\eb5c";
}
.icon-stumbleupon2:before {
  content: "\eb5d";
}
.icon-stackoverflow:before {
  content: "\eb5e";
}
.icon-pinterest:before {
  content: "\eb5f";
}
.icon-pinterest2:before {
  content: "\eb60";
}
.icon-xing:before {
  content: "\eb61";
}
.icon-xing2:before {
  content: "\eb62";
}
.icon-flattr:before {
  content: "\eb63";
}
.icon-foursquare:before {
  content: "\eb64";
}
.icon-yelp:before {
  content: "\eb65";
}
.icon-paypal:before {
  content: "\eb66";
}
.icon-chrome:before {
  content: "\eb67";
}
.icon-firefox:before {
  content: "\eb68";
}
.icon-IE:before {
  content: "\eb69";
}
.icon-edge:before {
  content: "\eb6a";
}
.icon-safari:before {
  content: "\eb6b";
}
.icon-opera:before {
  content: "\eb6c";
}
.icon-file-pdf:before {
  content: "\eb6d";
}
.icon-file-openoffice:before {
  content: "\eb6e";
}
.icon-file-word:before {
  content: "\eb6f";
}
.icon-file-excel:before {
  content: "\eb70";
}
.icon-libreoffice:before {
  content: "\eb71";
}
.icon-html-five:before {
  content: "\eb72";
}
.icon-html-five2:before {
  content: "\eb73";
}
.icon-css3:before {
  content: "\eb74";
}
.icon-git:before {
  content: "\eb75";
}
.icon-codepen:before {
  content: "\eb76";
}
.icon-svg:before {
  content: "\eb77";
}
.icon-IcoMoon:before {
  content: "\eb78";
}
